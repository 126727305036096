import React, {useEffect, useState} from 'react';
import {Box, LinearProgress} from "@mui/material";
import {getNotice} from "../api/ProcurementSystemApi";
import {ProcurementNotice} from "../types/ProcurementNoticeList";
import Grid from "@mui/material/Unstable_Grid2";

function Notice() {
    const searchParams = new URLSearchParams(window.location.search);
    const noticeId = (searchParams.get('noticeID') as string) || null;
    const [searchInProgress, setSearchInProgress] = React.useState<boolean>(false);
    const [result, setResult] = useState<ProcurementNotice | undefined>(undefined);

    useEffect(() => {
        // Start search call if we haven't gotten an answer, and we haven't already asked
        if (noticeId && result === undefined && !searchInProgress) {
            setSearchInProgress(true)
            getNotice(noticeId)
                .then((data) => {
                    let notice = data.hits[0];
                    setResult(notice);
                })
                .finally(() => {
                    // Search is no longer in progress
                    setSearchInProgress(false)
                });
        }
    }, [noticeId, result, searchInProgress]);


    return (
        <div>
            {searchInProgress && (
                <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
            )}
            {result && (
                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        alignContent: 'stretch',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '2px'
                    }}>
                        {JSON.stringify(result)}
                    </Box>
                </Grid>
            )}
        </div>
    );
}

export default Notice;
